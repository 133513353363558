/* eslint-disable react/no-unknown-property, max-len, react/prop-types, no-unused-vars, jsx-a11y/anchor-is-valid */
import React from 'react';
import Slide from '../Components/RevealComponents/Slide';
import Link from '../Components/Link';
import '../animation.css';

const Svg = () => (
  <svg
    id="text"
    width="100%"
    height="100%"
    preserveAspectRatio="xMinYMin meet"
    viewBox="0 0 1169 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M97.2266 110H70.2266V63.875H30.3594V110H3.35938V5.30469H30.3594V43.625H70.2266V5.30469H97.2266V110Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
    <path
      d="M199.742 110H124.016V5.30469H199.742V25.5547H150.875V43.625H196.227V63.875H150.875V89.75H199.742V110Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
    <path
      d="M297.969 110H222.453V5.30469H249.453V89.75H297.969V110Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
    <path
      d="M389.797 110H314.281V5.30469H341.281V89.75H389.797V110Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
    <path
      d="M507.922 57.6875C507.922 74.375 503.141 87.6406 493.578 97.4844C484.016 107.281 470.797 112.18 453.922 112.18C437.094 112.18 423.898 107.281 414.336 97.4844C404.773 87.6406 399.992 74.375 399.992 57.6875C399.992 40.8594 404.773 27.5703 414.336 17.8203C423.898 8.02344 437.094 3.125 453.922 3.125C470.703 3.125 483.898 8.02344 493.508 17.8203C503.117 27.5703 507.922 40.8594 507.922 57.6875ZM472.133 84.1953C474.758 81.0078 476.703 77.2578 477.969 72.9453C479.234 68.5859 479.867 63.4766 479.867 57.6172C479.867 51.3359 479.141 45.9922 477.688 41.5859C476.234 37.1797 474.336 33.6172 471.992 30.8984C469.602 28.0859 466.836 26.0469 463.695 24.7812C460.602 23.5156 457.367 22.8828 453.992 22.8828C450.57 22.8828 447.336 23.4922 444.289 24.7109C441.289 25.9297 438.523 27.9453 435.992 30.7578C433.648 33.3828 431.727 37.0156 430.227 41.6562C428.773 46.25 428.047 51.5938 428.047 57.6875C428.047 63.9219 428.75 69.2422 430.156 73.6484C431.609 78.0078 433.508 81.5703 435.852 84.3359C438.195 87.1016 440.938 89.1406 444.078 90.4531C447.219 91.7656 450.523 92.4219 453.992 92.4219C457.461 92.4219 460.766 91.7656 463.906 90.4531C467.047 89.0938 469.789 87.0078 472.133 84.1953Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
    <path
      d="M722.305 5.30469L694.109 110H664.227L645.453 41.7969L627.172 110H597.289L569.094 5.30469H597.289L613.391 77.375L632.656 5.30469H659.586L677.938 77.375L694.812 5.30469H722.305Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
    <path
      d="M842.188 57.6875C842.188 74.375 837.406 87.6406 827.844 97.4844C818.281 107.281 805.062 112.18 788.188 112.18C771.359 112.18 758.164 107.281 748.602 97.4844C739.039 87.6406 734.258 74.375 734.258 57.6875C734.258 40.8594 739.039 27.5703 748.602 17.8203C758.164 8.02344 771.359 3.125 788.188 3.125C804.969 3.125 818.164 8.02344 827.773 17.8203C837.383 27.5703 842.188 40.8594 842.188 57.6875ZM806.398 84.1953C809.023 81.0078 810.969 77.2578 812.234 72.9453C813.5 68.5859 814.133 63.4766 814.133 57.6172C814.133 51.3359 813.406 45.9922 811.953 41.5859C810.5 37.1797 808.602 33.6172 806.258 30.8984C803.867 28.0859 801.102 26.0469 797.961 24.7812C794.867 23.5156 791.633 22.8828 788.258 22.8828C784.836 22.8828 781.602 23.4922 778.555 24.7109C775.555 25.9297 772.789 27.9453 770.258 30.7578C767.914 33.3828 765.992 37.0156 764.492 41.6562C763.039 46.25 762.312 51.5938 762.312 57.6875C762.312 63.9219 763.016 69.2422 764.422 73.6484C765.875 78.0078 767.773 81.5703 770.117 84.3359C772.461 87.1016 775.203 89.1406 778.344 90.4531C781.484 91.7656 784.789 92.4219 788.258 92.4219C791.727 92.4219 795.031 91.7656 798.172 90.4531C801.312 89.0938 804.055 87.0078 806.398 84.1953Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
    <path
      d="M921.57 37.3672C921.57 34.7422 921.031 32.4922 919.953 30.6172C918.875 28.7422 917.023 27.2656 914.398 26.1875C912.57 25.4375 910.438 24.9922 908 24.8516C905.562 24.6641 902.727 24.5703 899.492 24.5703H889.719V52.7656H898.016C902.328 52.7656 905.938 52.5547 908.844 52.1328C911.75 51.7109 914.188 50.75 916.156 49.25C918.031 47.7969 919.391 46.2031 920.234 44.4688C921.125 42.6875 921.57 40.3203 921.57 37.3672ZM963.688 110H930.711L902.164 71.6094H889.719V110H862.859V5.30469H908.141C914.328 5.30469 919.648 5.65625 924.102 6.35938C928.555 7.0625 932.727 8.58594 936.617 10.9297C940.555 13.2734 943.672 16.3203 945.969 20.0703C948.312 23.7734 949.484 28.4375 949.484 34.0625C949.484 41.7969 947.68 48.1016 944.07 52.9766C940.508 57.8516 935.398 61.9062 928.742 65.1406L963.688 110Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M1051.02 110H975.5V5.30469H1002.5V89.75H1051.02V110Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
    <path
      d="M1166.26 57.7578C1166.26 67.5078 1164.03 76.25 1159.58 83.9844C1155.12 91.6719 1149.5 97.5781 1142.7 101.703C1137.59 104.797 1131.99 106.953 1125.9 108.172C1119.8 109.391 1112.59 110 1104.24 110H1067.33V5.30469H1105.3C1113.83 5.30469 1121.19 6.03125 1127.38 7.48438C1133.56 8.89062 1138.77 10.9062 1142.98 13.5312C1150.2 17.9375 1155.88 23.8906 1160 31.3906C1164.17 38.8438 1166.26 47.6328 1166.26 57.7578ZM1138.34 57.5469C1138.34 50.6562 1137.08 44.7734 1134.55 39.8984C1132.06 34.9766 1128.1 31.1328 1122.66 28.3672C1119.9 27.0078 1117.06 26.0938 1114.16 25.625C1111.3 25.1094 1106.96 24.8516 1101.15 24.8516H1094.33V90.3828H1101.15C1107.57 90.3828 1112.28 90.1016 1115.28 89.5391C1118.28 88.9297 1121.21 87.8516 1124.07 86.3047C1128.99 83.4922 1132.6 79.7422 1134.9 75.0547C1137.2 70.3203 1138.34 64.4844 1138.34 57.5469Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="square"
    />
  </svg>
);

const CSSAnimationSlide = ({ transition }) => (
  <>
  <Slide transition={transition}>
    <Svg />
    <aside>Credit: Issa Pourgholam</aside>
    <Link href="https://codepen.io/triboot">https://codepen.io/triboot</Link>
    </Slide>
    </>
);

export default CSSAnimationSlide;
